import { type IconIndexProps } from './types'

import ArrowLeftIcon from './arrow-left'
import ArrowRightIcon from './arrow-right'
import CartIcon from './cart'
import ChevronRightIcon from './chevron-right'
import PlayIcon from './play'
import ProfileIcon from './profile'

export { type CustomIconType } from './types'

const CustomIcon = ({ name, ...iconProps }: IconIndexProps) => {
  switch (name) {
    case 'ArrowLeft': {
      return <ArrowLeftIcon {...iconProps} />
    }

    case 'ArrowRight': {
      return <ArrowRightIcon {...iconProps} />
    }

    case 'Cart': {
      return <CartIcon {...iconProps} />
    }

    case 'ChevronRight': {
      return <ChevronRightIcon {...iconProps} />
    }

    case 'Play': {
      return <PlayIcon {...iconProps} />
    }

    case 'Profile': {
      return <ProfileIcon {...iconProps} />
    }
  }
}

export default CustomIcon
