import { type MouseEventHandler } from 'react'
import cx from 'classnames'

import ChevronDownIcon from '@components/icons/chevron-down'

interface DropdownButtonProps {
  id: string
  label: string
  isActive: boolean
  className?: string
  iconClassName?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const DropdownButton = ({
  id,
  label,
  isActive,
  className,
  iconClassName,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: DropdownButtonProps) => {
  return (
    <button
      aria-expanded={isActive}
      aria-controls={`dropdown-button-${id}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(
        'group inline-flex items-center',
        isActive && '!underline',
        className
      )}
    >
      {label}
      <ChevronDownIcon
        id={`dropdown-button-icon-${id}`}
        className={cx(
          'transition-transform duration-300',
          isActive && '-scale-y-100',
          iconClassName
        )}
      />
    </button>
  )
}

export default DropdownButton
