import cx from 'classnames'
import { useContext, useRef, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

import { LanguageContext, localeNames } from '@lib/language'
import { SiteContext } from '@lib/site'

import Flag from './flag'
import CheckmarkIcon from './icons/checkmark'

interface LanguageSwitchProps {
  id: string
  className?: string
}

const LanguageSwitchDesktop = ({ id, className }: LanguageSwitchProps) => {
  const { locale, publicLocales, changeLocale } = useContext(LanguageContext)
  const { toggleMegaNavigation } = useContext(SiteContext)

  const ref = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ref, () => {
    if (isOpen) {
      toggleMegaNavigation(false)
      setIsOpen(false)
    }
  })

  return (
    <div
      className={cx(
        'relative inline-flex items-center !no-underline',
        className
      )}
      ref={ref}
    >
      <button
        type="button"
        onClick={() => {
          toggleMegaNavigation(false)
          setIsOpen((currentIsOpen) => !currentIsOpen)
        }}
        className="inline-flex gap-x-2.5 mx-2"
      >
        <Flag
          id={`current-${id}-desktop`}
          locale={locale}
          className="w-[24px] h-[24px]"
        />
        <span className="uppercase">{locale}</span>
      </button>

      {isOpen && (
        <div className="min-w-full flex flex-col gap-y-2.5 p-4 mt-4 absolute z-100 top-full left-4 bg-white rounded-md shadow-lg">
          {publicLocales.map((publicLocale) => (
            <button
              type="button"
              className="flex lg:inline-flex items-center justify-between gap-x-8 group"
              key={`language-switch-${publicLocale}`}
              onClick={() => changeLocale(publicLocale)}
            >
              <span className="inline-flex items-center gap-x-2.5 group-hover:underline">
                <Flag
                  id={`${id}-desktop`}
                  locale={publicLocale}
                  className="w-[24px] h-[24px]"
                />
                {localeNames[publicLocale]}
              </span>

              {locale === publicLocale && (
                <CheckmarkIcon id="current-language-desktop" />
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default LanguageSwitchDesktop
