import { type CSSProperties } from 'react'

interface MegaNavigationBackgroundProps {
  height: number
}

type CSSPropertiesWithHeight = CSSProperties & {
  '--h': string
}

const MegaNavigationBackground = ({
  height,
}: MegaNavigationBackgroundProps) => {
  const style: CSSPropertiesWithHeight = {
    '--h': `${height}`,
  }

  return (
    <div className="hidden lg:block" style={style}>
      <div className="absolute inset-x-0 top-0 h-px will-change-transform origin-top-left scale-y-[var(--h)] transition-transform duration-[.8s] ease-custom-2 z-20 bg-white" />
    </div>
  )
}

export default MegaNavigationBackground
