import cx from 'classnames'
import FocusTrap from 'focus-trap-react'
import { useContext } from 'react'

import { type SanityHeaderFragment } from '@data/sanity/queries/types/site'
import { SiteContext } from '@lib/site'
import { StringsContext } from '@lib/strings'

import Hamburger from './hamburger'
import MobileNavigationBackdrop from './mobile-navigation-backdrop'
import MobileNavigationMenu from './mobile-navigation-menu'

type MobileNavigationProps = Pick<
  SanityHeaderFragment,
  'menuMobilePrimary' | 'menuMobileSecondary'
> & {
  isTransparent: boolean
}

const MobileNavigation = ({
  menuMobilePrimary,
  menuMobileSecondary,
  isTransparent,
}: MobileNavigationProps) => {
  const strings = useContext(StringsContext)
  const { mobileMenu, toggleMobileMenu } = useContext(SiteContext)

  return (
    <nav
      id="mobile-nav"
      className={cx('lg:hidden flex justify-end items-center')}
    >
      <FocusTrap active={mobileMenu.isOpen}>
        <div className="flex items-center">
          <button
            onClick={() => toggleMobileMenu(!mobileMenu.isOpen)}
            aria-expanded={mobileMenu.isOpen ? 'true' : 'false'}
            aria-controls="mobile-nav"
            aria-label={strings.mobileMenuLabel}
            className={cx(
              'lg:hidden absolute left-0 top-0 bottom-0 z-60 pl-4 pr-4 appearance-none no-underline cursor-pointer font-inherit rounded-full',
              {
                'text-pageText': mobileMenu.isOpen,
                'transition-colors duration-300': isTransparent,
              }
            )}
          >
            <Hamburger isOpened={mobileMenu.isOpen} />
          </button>

          <MobileNavigationMenu
            menuMobilePrimary={menuMobilePrimary}
            menuMobileSecondary={menuMobileSecondary}
          />
          <MobileNavigationBackdrop isOpen={mobileMenu.isOpen} />
        </div>
      </FocusTrap>
    </nav>
  )
}

export default MobileNavigation
