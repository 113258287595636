import axios, { type AxiosResponse } from 'axios'
import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import dynamic from 'next/dynamic'
import { type FormEvent, useContext, useState } from 'react'

import { type SanityNewsletterBlock } from '@data/sanity/queries/types/blocks'
import {
  newsletterSignUpEventName,
  triggerGoogleTagManagerEvent,
} from '@lib/analytics'
import { fadeAnimation } from '@lib/animate'
import { LanguageContext } from '@lib/language'
import { StringsContext } from '@lib/strings'
import { type NewsletterResponse } from '@pages/api/klaviyo/newsletter-join'

import Alert from '@components/alert'
import Button from '@components/buttons/button'
import { CheckboxSvg } from '@components/checkbox'

const ComplexPortableText = dynamic(
  () => import('@components/complex-portable-text')
)
const SimplePortableText = dynamic(
  () => import('@components/simple-portable-text')
)

interface NewsletterProps
  extends Omit<SanityNewsletterBlock, '_key' | '_type'> {
  id: string
  className?: string
  isInvertedColors?: boolean
  isInlineButton?: boolean
}

interface NewsletterFormValues {
  fullname: string
  email: string
  acceptTerms?: boolean
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const Newsletter = ({
  id,
  service,
  hubSpotFormId,
  klaviyoListID,
  variant,
  title,
  description,
  terms,
  submit,
  successMsg,
  errorMsg,
  className,
  isInvertedColors,
  isInlineButton,
}: NewsletterProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const [email, setEmail] = useState('')
  const [acceptTermsChecked, setAcceptTermsChecked] = useState(false)

  const resetForm = (event: FormEvent) => {
    event.preventDefault()

    setEmail('')
    setAcceptTermsChecked(false)

    setIsError(false)
    setIsSuccess(false)
    setIsSubmitting(false)
  }

  const addToNewsletter = async (
    newsletterFormValues: NewsletterFormValues
  ) => {
    if (!service) {
      return
    }

    const payload = JSON.stringify({
      ...(service === 'klaviyo' && {
        listID: klaviyoListID,
      }),
      ...(service === 'hubSpot' && {
        formId: hubSpotFormId,
        consent: newsletterFormValues.acceptTerms ?? false,
      }),
      ...newsletterFormValues,
    })

    const url = `/api/${service.toLowerCase()}/newsletter-join`

    try {
      await axios.post<
        NewsletterResponse,
        AxiosResponse<NewsletterResponse>,
        string
      >(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      })

      setIsSubmitting(false)
      setIsSuccess(true)

      triggerGoogleTagManagerEvent(newsletterSignUpEventName)
    } catch (error) {
      setIsSubmitting(false)
      setIsError(true)
      console.log(error)
    }
  }

  const isExtended = variant === 'extended'
  const hasAgreed = terms ? acceptTermsChecked : true
  const isDisabled = isSubmitting || !hasAgreed || !emailRegex.test(email)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    // Set an error if there is no Klaviyo list supplied
    if (service === 'klaviyo' && !klaviyoListID) {
      setIsError(true)
      return
    }

    // Set an error if there is no HubSpot form ID supplied
    if (service === 'hubSpot' && !hubSpotFormId) {
      setIsError(true)
      return
    }

    // Stop if accepting of terms ir required
    if (!hasAgreed && terms) {
      return
    }

    setIsSubmitting(true)
    setIsError(false)
    addToNewsletter({
      fullname: '',
      email,
      acceptTerms: acceptTermsChecked,
    })
  }

  if (!service) {
    return null
  }

  const form = (
    <form onSubmit={handleSubmit} className={className}>
      <AnimatePresence mode="wait">
        {!isError && !isSuccess && (
          <motion.div
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            {isExtended && (
              <input
                id={`newsletter-${id}-email`}
                type="email"
                name="email"
                onBlur={(event) => {
                  setEmail(event.target.value)
                }}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
                placeholder={strings.emailAddress}
                className="p-4 mb-5 rounded-full w-full max-w-[300px] sm:max-w-[400px] text-black"
              />
            )}

            {!isExtended && (
              <div className="grid mb-4">
                <div className="flex flex-col relative text-left">
                  <div
                    className={cx('flex justify-between border-b', {
                      'border-input-border': !isInvertedColors,
                      'border-input-inverted-border': isInvertedColors,
                    })}
                  >
                    <input
                      id={`newsletter-${id}-email`}
                      type="email"
                      inputMode="email"
                      name="email"
                      value={email}
                      placeholder={strings.emailAddress}
                      onBlur={(event) => {
                        setEmail(event.target.value)
                      }}
                      onChange={(event) => {
                        setEmail(event.target.value)
                      }}
                      className={cx(
                        'relative appearance-none w-full h-full py-3 text-base leading-none',
                        {
                          'bg-input-bg text-input-text': !isInvertedColors,
                          'inverted bg-input-inverted-bg text-input-inverted-text':
                            isInvertedColors,
                        }
                      )}
                    />
                    {isInlineButton && (
                      <Button
                        id={`newsletter-${id}-submit`}
                        className="ml-3 text-xs whitespace-nowrap uppercase !no-underline"
                        type="submit"
                        size="small"
                        disabled={isDisabled}
                      >
                        {submit}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}

            {terms && (
              <div className="relative mb-4">
                <input
                  id={`newsletter-${id}-acceptTerms-checkbox`}
                  type="checkbox"
                  name="acceptTerms"
                  checked={acceptTermsChecked}
                  onChange={(event) => {
                    setAcceptTermsChecked(event.target.checked)
                  }}
                  className="absolute w-[1px] h-[1px] p-0 m-[-1px] overflow-hidden whitespace-nowrap border-none opacity-0"
                />
                <label
                  htmlFor={`newsletter-${id}-acceptTerms-checkbox`}
                  className="relative inline-flex transform-none top-auto left-auto mx-auto p-0 cursor-pointer"
                >
                  <CheckboxSvg
                    isChecked={acceptTermsChecked}
                    className={cx('rounded-sm', {
                      'border-input-border': !isInvertedColors,
                      'border-input-inverted-border': isInvertedColors,
                      'delay-150': !acceptTermsChecked,
                      'delay-0': acceptTermsChecked,
                    })}
                  />
                  <div className="flex items-center">
                    <SimplePortableText
                      content={terms}
                      className="rc rc-checkbox"
                    />
                  </div>
                </label>
              </div>
            )}

            {!isInlineButton && (
              <Button
                id={`newsletter-${id}-submit`}
                type="submit"
                className={cx({
                  'w-full mt-3': !isExtended,
                  'w-auto uppercase mt-1': isExtended,
                })}
                size="normal"
                color="yellow"
                variant="primary"
                disabled={isDisabled}
              >
                {submit}
              </Button>
            )}
          </motion.div>
        )}

        {isSuccess && (
          <motion.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
            className={cx({
              'max-w-[400px] mx-auto': isExtended,
            })}
          >
            <Alert>
              <ComplexPortableText
                content={successMsg}
                className="rc rc-alert"
              />
            </Alert>
          </motion.div>
        )}

        {isError && (
          <motion.div
            key="error"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
            className={cx({
              'max-w-[400px] mx-auto': isExtended,
            })}
          >
            <Alert
              buttonText={strings.buttonTryAgain}
              onClick={resetForm}
              buttonColor={isExtended ? 'yellow' : 'default'}
            >
              <ComplexPortableText content={errorMsg} className="rc rc-alert" />
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  )

  if (isExtended) {
    return (
      <div className="bg-green-dark text-green-light py-8 xs:py-10 md:py-12 lg:py-20 px-5 xs:px-10 flex flex-col justify-center items-center rounded-md">
        {title && (
          <h4 className="text-center text-yellow mb-4 max-w-4xl">{title}</h4>
        )}

        {description && (
          <p className="text-base text-center text-white max-w-3xl">
            {description}
          </p>
        )}
        <div className="w-full text-center mt-8">{form}</div>
      </div>
    )
  }

  return form
}

export default Newsletter
