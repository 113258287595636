import cx from 'classnames'
import dynamic from 'next/dynamic'

import {
  type SanityFooterBlock1,
  type SanityFooterBlock2,
  type SanityFooterBlock3,
  type SanityFooterBlock4,
} from '@data/sanity/queries/types/site'

import Menu from '@blocks/navigation/menu'
import Newsletter from '@blocks/newsletter'

const SimplePortableText = dynamic(
  () => import('@components/simple-portable-text')
)

interface FooterBlockProps {
  block:
    | SanityFooterBlock1
    | SanityFooterBlock2
    | SanityFooterBlock3
    | SanityFooterBlock4
  className?: string
}

const FooterBlock = ({ block, className }: FooterBlockProps) => {
  const id = `footer-${block.title.toLowerCase()}`

  return (
    <div
      className={cx(
        'flex flex-col text-left',
        {
          'lg:order-first': 'newsletter' in block,
          'lg:order-last': 'social' in block,
        },
        className
      )}
    >
      <h2 className="text-2xl leading-tight mb-4">{block.title}</h2>

      {'description' in block && block.description && (
        <SimplePortableText content={block.description} className="rc mb-5" />
      )}

      {'menu' in block && block.menu.items.length > 0 && (
        <Menu id={id} items={block.menu.items} isFooterMenu />
      )}

      {'newsletter' in block && (
        <Newsletter
          id={id}
          service={block.newsletter.service}
          hubSpotFormId={block.newsletter.hubSpotFormId}
          klaviyoListID={block.newsletter.klaviyoListID}
          submit={block.newsletter.submit}
          successMsg={block.newsletter.successMsg}
          errorMsg={block.newsletter.errorMsg}
          terms={block.newsletter.terms}
          isInlineButton
        />
      )}

      {'social' in block && block.social && (
        <ul className="flex flex-col lg:mb-8 px-0">
          {block.social.map((link) => (
            <li className="mb-2" key={link._key}>
              <a
                id={`footer-social-link-${link.icon.toLowerCase()}`}
                className="hover:underline underline-offset-4 inline-block text-base text-left"
                key={link._key}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.icon}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default FooterBlock
