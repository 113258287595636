import cx from 'classnames'

import { type IconProps } from './types'

const ArrowRightIcon = ({ id, color, viewBox, className }: IconProps) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox ?? '0 0 28 28'}
      aria-labelledby={`arrowright-${id}`}
      className={cx('w-[1em] h-[1em]', className)}
    >
      <title id={`arrowright-${id}`}>ArrowRight</title>

      <rect
        x="2"
        y="12"
        width="21"
        height="2"
        fill={color}
        className="fill-current"
      />
      <rect
        width="13.9673"
        height="2"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 25.071 13.1235)"
        fill={color}
        className="fill-current"
      />
      <rect
        width="13.9673"
        height="2"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 15.1948 23.001)"
        fill={color}
        className="fill-current"
      />
    </svg>
  )
}

export default ArrowRightIcon
