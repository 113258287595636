import cx from 'classnames'

import { type SanityLink } from '@data/sanity/queries/types/content'
import { type SanityLinkFragment } from '@data/sanity/queries/types/link'
import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'

import LanguageSwitchDesktop from '@components/language-switch-desktop'
import LanguageSwitchMobile from '@components/language-switch-mobile'
import CustomLink from '@components/link'
import MegaNavigationItem from './mega-navigation-item'
import MegaNavigationButton from './mega-navigation-button'
import Dropdown from './dropdown'

export type ItemType = SanityLinkFragment | SanityMenuFeaturedLinkFragment

interface MenuItemProps {
  menuId?: string
  item: ItemType
  hasFocus: boolean
  useMegaNav: boolean
  onClick?: () => void
  isFooterMenuItem?: boolean
  isHeaderDesktopMenuItem?: boolean
  isHeaderMobilePrimaryMenuItem?: boolean
  isHeaderMobileSecondaryMenuItem?: boolean
  isMegaNavMenuItem?: boolean
}

const MenuItem = ({
  menuId,
  item,
  hasFocus,
  useMegaNav,
  onClick,
  isFooterMenuItem,
  isHeaderDesktopMenuItem,
  isHeaderMobilePrimaryMenuItem,
  isHeaderMobileSecondaryMenuItem,
  isMegaNavMenuItem,
}: MenuItemProps) => {
  const title = 'title' in item && item.title ? item.title : ''
  const isButton = 'isButton' in item && item.isButton ? item.isButton : false

  const itemClassNames = cx(
    !isButton && [
      'no-underline hover:underline !decoration-auto',
      {
        'underline-offset-4': isHeaderDesktopMenuItem,
        'inline-block text-base text-left': isFooterMenuItem,
        'block font-medium text-base p-2': isHeaderDesktopMenuItem,
        'block p-0 text-4xl text-left py-1': isHeaderMobilePrimaryMenuItem,
        'block p-0 text-xl text-left': isHeaderMobileSecondaryMenuItem,
        'inline-block text-4xl leading-normal': isMegaNavMenuItem,
      },
    ]
  )

  // Language switch
  if (item._type === 'navLanguageSwitch') {
    if (isHeaderMobilePrimaryMenuItem || isHeaderMobileSecondaryMenuItem) {
      return <LanguageSwitchMobile id={item._key} className={itemClassNames} />
    }

    return <LanguageSwitchDesktop id={item._key} className={itemClassNames} />
  }

  if (isMegaNavMenuItem) {
    return <MegaNavigationItem item={item} onClick={onClick} />
  }

  // Dropdown list
  if (item._type === 'navDropdown') {
    if (useMegaNav) {
      return (
        <MegaNavigationButton
          id={item._key}
          title={title}
          className={itemClassNames}
        />
      )
    }

    if (!('dropdownItems' in item) || !item.dropdownItems) {
      return null
    }

    return (
      <Dropdown
        id={item._key}
        title={title}
        items={item.dropdownItems}
        buttonClassName={itemClassNames}
      />
    )
  }

  // Single link
  const titleSlug =
    'title' in item ? item.title.toLowerCase().replace(/ /g, '-') : ''
  const link: SanityLink = {
    ...item,
    ...(menuId && {
      id: `${menuId}-${titleSlug}`,
    }),
  }

  return (
    <CustomLink
      link={link}
      onClick={onClick}
      tabIndex={!hasFocus ? -1 : 0}
      className={itemClassNames}
    >
      {title}
    </CustomLink>
  )
}

export default MenuItem
