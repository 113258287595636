import cx from 'classnames'

import { type IconProps } from './types'

const PlayIcon = ({ id, color, viewBox, className }: IconProps) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox ?? '0 0 15 18'}
      aria-labelledby={`play-${id}`}
      className={cx('w-[1em] h-[1em]', className)}
    >
      <title id={`play-${id}`}>Play</title>
      <path
        d="M14.5239 9.00001L0.23821 17.2479L0.238211 0.752144L14.5239 9.00001Z"
        fill={color}
        className="fill-current"
      />
    </svg>
  )
}

export default PlayIcon
