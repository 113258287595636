import cx from 'classnames'
import { useContext } from 'react'

import { SiteContext } from '@lib/site'

interface MegaNavigationBackdropProps {
  isOpen: boolean
}

const MegaNavigationBackdrop = ({ isOpen }: MegaNavigationBackdropProps) => {
  const { toggleMegaNavigation } = useContext(SiteContext)

  return (
    <div
      role="presentation"
      onClick={() => toggleMegaNavigation(false)}
      onBeforeInput={() => toggleMegaNavigation(false)}
      className={cx(
        'hidden lg:block fixed inset-0 z-0 bg-backdrop bg-opacity-40 transition-opacity duration-150 ease-linear',
        {
          'pointer-events-none opacity-0': !isOpen,
          'pointer-events-auto opacity-100 backdrop-blur-[6px]': isOpen,
        }
      )}
    />
  )
}

export default MegaNavigationBackdrop
