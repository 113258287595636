import { type Locale } from '@lib/language'

interface FlagProps {
  id: string
  locale: Locale
  className?: string
}

const Flag = ({ id, locale, className }: FlagProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath={`url(#${id}-${locale}-flag-clip-path)`}>
      <mask
        id={`${id}-${locale}-flag-mask`}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        {locale === 'en' && (
          <path
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0C5.37259 0 0 5.37259 0 12C0 18.6274 5.37259 24 12 24Z"
            fill="white"
          />
        )}

        {locale === 'da' && <path d="M24 0H0V24H24V0Z" fill="white" />}
      </mask>

      <g mask={`url(#${id}-${locale}-flag-mask)`}>
        {locale === 'en' && (
          <>
            <path d="M24 0H0V24H24V0Z" fill="#231D9A" />
            <path
              d="M10.125 10.125V-0.375H9V7.6875L0.9375 2.25L-1.5 5.4375L3.9375 9H-0.9375V10.125H10.125Z"
              fill="white"
            />
            <path
              d="M10.125 13.875V24.375H9V16.3125L0.9375 21.75L-1.5 18.5625L3.9375 15H-0.9375V13.875H10.125Z"
              fill="white"
            />
            <path
              d="M13.875 10.125V-0.375H15V7.6875L23.0625 2.25L25.5 5.4375L20.0625 9H24.9375V10.125H13.875Z"
              fill="white"
            />
            <path
              d="M13.875 13.875V24.375H15V16.3125L23.0625 21.75L25.5 18.5625L20.0625 15H24.9375V13.875H13.875Z"
              fill="white"
            />
            <path
              d="M9 9V8.8125L1.125 3.5625L-0.1875 5.0625L5.625 9H9Z"
              fill="#F0263C"
            />
            <path
              d="M9 15V15.1875L1.125 20.4375L-0.1875 18.9375L5.625 15H9Z"
              fill="#F0263C"
            />
            <path
              d="M15 9V8.8125L22.875 3.5625L24.1875 5.0625L18.375 9H15Z"
              fill="#F0263C"
            />
            <path
              d="M15 15V15.1875L22.875 20.4375L24.1875 18.9375L18.375 15H15Z"
              fill="#F0263C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.875 10.125V-0.75H10.125V10.125H-0.75V13.875H10.125V24.75H13.875V13.875H24.75V10.125H13.875Z"
              fill="#F0263C"
            />
          </>
        )}

        {locale === 'da' && (
          <>
            <path
              d="M5.00031 2.25C2.47529 4.07501 0.700294 6.825 0.175293 10H5.00031V2.25Z"
              fill="#F0263C"
            />
            <path
              d="M23.825 10C22.875 4.32499 17.95 0 12 0C10.975 0 9.94999 0.125 9 0.375V10H23.825Z"
              fill="#F0263C"
            />
            <path
              d="M0.175293 14C0.700294 17.175 2.47529 19.925 5.00031 21.75V14H0.175293Z"
              fill="#F0263C"
            />
            <path
              d="M9 23.625C9.94999 23.875 10.975 24 12 24C17.95 24 22.875 19.675 23.825 14H9V23.625Z"
              fill="#F0263C"
            />
            <path
              d="M23.95 11C23.925 10.675 23.875 10.325 23.825 10H9V0.375C8.65001 0.45 8.325 0.574999 7.99999 0.675C7.30001 0.925001 6.62501 1.225 6 1.6C5.65001 1.8 5.325 2.025 4.99999 2.25V10H0.175C0.125 10.325 0.075 10.675 0.0499999 11C0.025 11.325 0 11.675 0 12C0 12.325 0.025 12.675 0.0499999 13C0.075 13.325 0.125 13.675 0.175 14H4.99999V21.75C5.325 21.975 5.65001 22.2 6 22.4C6.62501 22.775 7.30001 23.075 7.99999 23.325C8.325 23.45 8.65001 23.55 9 23.625V14H23.825C23.875 13.675 23.925 13.325 23.95 13C23.975 12.675 24 12.325 24 12C24 11.675 23.975 11.325 23.95 11Z"
              fill="white"
            />
          </>
        )}
      </g>
    </g>
    <defs>
      <clipPath id={`${id}-${locale}-flag-clip-path`}>
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Flag
