import cx from 'classnames'
import { useContext } from 'react'

import { SiteContext } from '@lib/site'
import { useIsTouchDevice } from '@lib/helpers'

import DropdownButton from './dropdown-button'

interface MegaNavigationButtonProps {
  id: string
  title: string
  className?: string
}

const MegaNavigationButton = ({
  id,
  title,
  className,
}: MegaNavigationButtonProps) => {
  const isTouchDevice = useIsTouchDevice()
  const {
    megaNavigation,
    megaNavigationTimeout,
    setMegaNavigationTimeout,
    toggleMegaNavigation,
  } = useContext(SiteContext)

  const isActive = megaNavigation?.activeId === id

  const handleClick = () => {
    if (isTouchDevice) {
      toggleMegaNavigation(!isActive ? true : 'toggle', id)
    }
  }

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      if (megaNavigationTimeout) {
        window.clearTimeout(megaNavigationTimeout)
      }

      toggleMegaNavigation(true, id)
    }
  }

  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      setMegaNavigationTimeout(
        window.setTimeout(() => {
          toggleMegaNavigation(false)
        }, 300)
      )
    }
  }

  return (
    <DropdownButton
      id={`meganav-${id}`}
      label={title}
      isActive={isActive}
      className={cx('gap-x-1.5', className)}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  )
}

export default MegaNavigationButton
