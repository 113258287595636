import cx from 'classnames'

import { type IconProps } from './types'

const ArrowLeftIcon = ({ id, color, viewBox, className }: IconProps) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox ?? '0 0 28 28'}
      aria-labelledby={`arrowleft-${id}`}
      className={cx('w-[1em] h-[1em]', className)}
    >
      <title id={`arrowleft-${id}`}>ArrowLeft</title>

      <rect
        x="25.071"
        y="14.2482"
        width="21"
        height="2"
        transform="rotate(-180 25.071 14.2482)"
        fill={color}
        className="fill-current"
      />
      <rect
        width="13.9673"
        height="2"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 2 13.1246)"
        fill={color}
        className="fill-current"
      />
      <rect
        width="13.9673"
        height="2"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 11.8762 3.24719)"
        fill={color}
        className="fill-current"
      />
    </svg>
  )
}

export default ArrowLeftIcon
