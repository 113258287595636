import cx from 'classnames'
import { useContext } from 'react'

import { LanguageContext, localeNames } from '@lib/language'

import Flag from './flag'
import CheckmarkIcon from './icons/checkmark'

interface LanguageSwitchMobileProps {
  id: string
  className?: string
}

const LanguageSwitchMobile = ({ id, className }: LanguageSwitchMobileProps) => {
  const { locale, publicLocales, changeLocale } = useContext(LanguageContext)

  return (
    <div className={cx('min-w-full flex flex-col gap-y-2.5 my-4', className)}>
      {publicLocales.map((publicLocale) => (
        <button
          type="button"
          className="flex lg:inline-flex items-center justify-between gap-x-8 group"
          key={`language-switch-${publicLocale}`}
          onClick={() => changeLocale(publicLocale)}
        >
          <span className="inline-flex items-center gap-x-2.5 group-hover:underline">
            <Flag
              id={`${id}-mobile`}
              locale={publicLocale}
              className="w-[24px] h-[24px]"
            />
            {localeNames[publicLocale]}
          </span>

          {locale === publicLocale && (
            <CheckmarkIcon id="current-language-mobile" />
          )}
        </button>
      ))}
    </div>
  )
}

export default LanguageSwitchMobile
