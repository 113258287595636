import cx from 'classnames'

import { type IconProps } from './types'

const ProfileIcon = ({ id, color, viewBox, className }: IconProps) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox ?? '0 0 28 28'}
      aria-labelledby={`profile-${id}`}
      className={cx('w-[1em] h-[1em]', className)}
    >
      <title id={`profile-${id}`}>Profile</title>
      <path
        d="M23 22C22.4437 20.0735 21.3175 19.0434 19.7873 17.8451C18.2571 16.6468 16.4037 16 14.5 16C12.5963 16 10.7429 16.6468 9.21271 17.8451C7.6825 19.0434 6.55631 20.0735 6 22V24H23V22Z"
        stroke={color}
        className="stroke-current"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14.5 13C16.9853 13 19 10.9853 19 8.5C19 6.01472 16.9853 4 14.5 4C12.0147 4 10 6.01472 10 8.5C10 10.9853 12.0147 13 14.5 13Z"
        stroke={color}
        className="stroke-current"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default ProfileIcon
