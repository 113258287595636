import cx from 'classnames'

import { type SanityLink } from '@data/sanity/queries/types/content'

import {
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import ButtonLink from '@components/buttons/button-link'
import { LinkProps } from './link'

type ExternalLinkProps = Pick<
  SanityLink,
  'id' | 'url' | 'fontCase' | 'buttonStyles' | 'isButton'
> &
  Pick<
    LinkProps,
    'buttonVariant' | 'buttonSize' | 'buttonColor' | 'children'
  > & {
    ariaLabel?: string
    tabIndex?: number
    className?: string
  }

const ExternalLink = ({
  id,
  url,
  ariaLabel,
  tabIndex,
  buttonVariant,
  buttonSize,
  buttonColor,
  buttonStyles,
  isButton,
  fontCase,
  className,
  children,
}: ExternalLinkProps) => {
  return (
    <ButtonLink
      id={id}
      href={url}
      target="_blank"
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      rel="noopener noreferrer"
      variant={buttonVariant ?? getButtonVariant(buttonStyles?.variant)}
      size={buttonSize ?? getButtonSize(buttonStyles?.size)}
      color={buttonColor ?? getButtonColor(buttonStyles?.color)}
      icon={buttonStyles?.icon}
      iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
      className={cx(
        { btn: isButton, 'w-full': buttonStyles?.isFullWidth },
        fontCase ?? '',
        className
      )}
    >
      {children}
    </ButtonLink>
  )
}

export default ExternalLink
