import cx from 'classnames'
import { type RefObject, useContext, useEffect, useState } from 'react'
import { useElementSize, useIntersectionObserver } from 'usehooks-ts'

import { type SanityHeaderFragment } from '@data/sanity/queries/types/site'
import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { SiteContext } from '@lib/site'

import Menu from '@blocks/navigation/menu'
import MegaNavigation from '@blocks/navigation/mega-navigation'
import MobileNavigation from '@blocks/navigation/mobile-navigation'
import Logo from './logo'

export interface HeaderSizeValues {
  height: number
}

type HeaderProps = Pick<
  SanityHeaderFragment,
  | 'menuDesktopLeft'
  | 'menuDesktopRight'
  | 'menuMobilePrimary'
  | 'menuMobileSecondary'
> & {
  isTransparent: boolean
  topObserverRef: RefObject<Element>
  logo?: SanityImageFragment
  invertedLogo?: SanityImageFragment
  onResize?: (newValues: HeaderSizeValues) => void
}

const Header = ({
  menuDesktopLeft,
  menuDesktopRight,
  menuMobilePrimary,
  menuMobileSecondary,
  isTransparent,
  logo,
  invertedLogo,
  topObserverRef,
  onResize,
}: HeaderProps) => {
  const { megaNavigation, toggleMegaNavigation } = useContext(SiteContext)

  const [isLoaded, setIsLoaded] = useState(false)

  const entry = useIntersectionObserver(topObserverRef, {
    freezeOnceVisible: false,
    threshold: 1,
  })
  const isInView = !!entry?.isIntersecting
  const isObserverVisible = isLoaded ? isInView : isTransparent

  const [headerRef, headerRectangle] = useElementSize()

  useEffect(() => {
    // Wait for intersection observer to load, then show header
    if (!isLoaded) {
      setTimeout(() => setIsLoaded(true), 200)
    }

    if (isLoaded && headerRectangle && onResize) {
      onResize({
        height: headerRectangle.height,
      })
    }
  }, [headerRectangle, isLoaded, onResize])

  return (
    <header
      className={cx('sticky top-0 inset-x-0 z-50 text-header-text', {
        'h-0': isTransparent,
      })}
    >
      <div ref={headerRef} className="relative">
        <div
          className={cx(
            'relative z-30 py-1 sm:py-4 md:py-5 transition-colors duration-300',
            {
              'bg-transparent': isTransparent && isObserverVisible,
              'bg-header-bg': !isTransparent || !isObserverVisible,
              'bg-opacity-95': !isObserverVisible,
              'text-white delay-75':
                isTransparent && !megaNavigation?.isOpen && isObserverVisible,
            }
          )}
        >
          <div className="lg:container flex flex-row justify-between items-center w-full relative z-30 min-h-[50px]">
            <MobileNavigation
              menuMobilePrimary={menuMobilePrimary}
              menuMobileSecondary={menuMobileSecondary}
              isTransparent={isTransparent}
            />

            <Logo
              logo={logo}
              invertedLogo={invertedLogo}
              isInvertedLogo={isTransparent && isObserverVisible}
            />

            <div className="block lg:hidden" />

            <nav className="hidden lg:flex items-center justify-center py-2 absolute left-0 right-0">
              {!!menuDesktopLeft.items && (
                <Menu
                  id={menuDesktopLeft.slug}
                  items={menuDesktopLeft.items}
                  useMegaNav
                  isHeaderDesktopMenu
                  onClick={() => toggleMegaNavigation(false)}
                />
              )}
            </nav>
            <nav className="hidden lg:flex items-center relative z-1">
              {!!menuDesktopRight.items && (
                <Menu
                  id={menuDesktopRight.slug}
                  items={menuDesktopRight.items}
                  useMegaNav
                  isHeaderDesktopMenu
                  onClick={() => toggleMegaNavigation(false)}
                  className="flex"
                />
              )}
            </nav>
          </div>
        </div>

        <MegaNavigation
          items={[...menuDesktopLeft.items, ...menuDesktopRight.items]}
          headerHeight={headerRectangle?.height ?? 0}
        />
      </div>
    </header>
  )
}

export default Header
