import cx from 'classnames'
import FocusTrap from 'focus-trap-react'
import { motion, type Variants } from 'framer-motion'
import {
  type KeyboardEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react'
import { useElementSize } from 'usehooks-ts'

import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { SiteContext } from '@lib/site'
import { useIsTouchDevice } from '@lib/helpers'

import MegaNavigationBackground from './mega-navigation-background'
import MegaNavigationBackdrop from './mega-navigation-backdrop'
import Menu from './menu'

interface MegaNavigationProps {
  items: SanityMenuFeaturedLinkFragment[]
  headerHeight: number
}

const swipeAnimation: Variants = {
  show: {
    opacity: 1,
    x: ['-1rem', '0rem'],
    transition: {
      x: {
        duration: 0.8,
        delay: 0.1,
        ease: [0.16, 1, 0.3, 1],
      },
      opacity: {
        duration: 0.2,
        delay: 0.1,
      },
    },
  },
  hide: {
    x: ['0rem', '1rem'],
    opacity: 0,
    transition: {
      x: {
        duration: 0.4,
        ease: [0.16, 1, 0.3, 1],
      },
      opacity: {
        duration: 0.1,
      },
    },
  },
}

const MegaNavigation = ({ items, headerHeight }: MegaNavigationProps) => {
  const {
    megaNavigation,
    megaNavigationTimeout,
    setMegaNavigationTimeout,
    toggleMegaNavigation,
  } = useContext(SiteContext)

  const [hasFocus, setHasFocus] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const [dropdownRef, dropdownRectangle] = useElementSize()
  const isTouchDevice = useIsTouchDevice()

  const dropdowns = useMemo(
    () =>
      items.filter(
        (item) => item._type === 'navDropdown' && 'dropdownItems' in item
      ),
    [items]
  )
  const activeLinkCount = useMemo(
    () =>
      dropdowns
        .map((dropdown) =>
          megaNavigation.isOpen && megaNavigation.activeId === dropdown._key
            ? dropdown.dropdownItems.length
            : 0
        )
        .reduce((sum, linkCount) => sum + linkCount, 0),
    [dropdowns, megaNavigation]
  )

  // Update dropdown open state when closing menu
  useEffect(() => {
    if (!megaNavigation.isOpen && isDropdownOpen) {
      setIsDropdownOpen(false)
    }
  }, [isDropdownOpen, megaNavigation.isOpen])

  // Toggle overflow-hidden body class
  useEffect(() => {
    if (!isLoaded && megaNavigation.isOpen) {
      setIsLoaded(true)
    }

    if (typeof window !== 'undefined') {
      document.body.classList.toggle('overflow-hidden', megaNavigation.isOpen)
    }
  }, [isLoaded, megaNavigation.isOpen])

  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent<HTMLDivElement>) => {
      if (key === 'Escape') {
        toggleMegaNavigation(false)
      }
    },
    [toggleMegaNavigation]
  )

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      if (megaNavigationTimeout) {
        window.clearTimeout(megaNavigationTimeout)
      }
    }
  }

  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      setMegaNavigationTimeout(
        window.setTimeout(() => {
          toggleMegaNavigation(false)
        }, 300)
      )
    }
  }

  if (dropdowns.length === 0 || !isLoaded) {
    return null
  }

  return (
    <>
      <FocusTrap
        active={megaNavigation.isOpen && hasFocus && activeLinkCount > 0}
        focusTrapOptions={{
          allowOutsideClick: true,
        }}
      >
        <div
          className="hidden lg:block absolute top-full inset-x-0 z-30 bg-pageBG"
          onKeyDown={handleKeyDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          role="menu"
          tabIndex={-1}
        >
          {dropdowns.map(({ _key, dropdownItems }) => {
            const isDropdownActive =
              megaNavigation.isOpen && megaNavigation.activeId === _key

            const dropdownCallbackRef = (element: HTMLDivElement | null) => {
              // Set dropdown reference when current it is open & start observing it
              if (isDropdownActive && element) {
                dropdownRef(element)

                if (!isDropdownOpen) {
                  setIsDropdownOpen(true)
                }
              }
            }

            return (
              <div
                key={_key}
                ref={dropdownCallbackRef}
                id={`meganav-${_key}`}
                className={cx(
                  'lg:container absolute top-0 inset-x-0 z-10 -mt-px overflow-hidden',
                  'transition-visibility duration-200',
                  {
                    'delay-[0s] pointer-events-auto': isDropdownActive,
                    'invisible delay-[.8s] pointer-events-none':
                      !isDropdownActive,
                  }
                )}
              >
                <div>
                  <div>
                    <motion.div
                      initial="hide"
                      animate={isDropdownActive ? 'show' : 'hide'}
                      onAnimationComplete={(definition) =>
                        setHasFocus(definition === 'show')
                      }
                      variants={swipeAnimation}
                      className="py-12 will-change-transform"
                    >
                      <Menu
                        items={dropdownItems}
                        hasFocus={hasFocus && isDropdownActive}
                        onClick={() => toggleMegaNavigation(false)}
                        isMegaNavMenu
                      />
                    </motion.div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </FocusTrap>

      <MegaNavigationBackground
        height={
          megaNavigation.isOpen
            ? headerHeight + (dropdownRectangle?.height ?? 0)
            : 0
        }
      />
      <MegaNavigationBackdrop isOpen={megaNavigation.isOpen} />
    </>
  )
}

export default MegaNavigation
