import cx from 'classnames'
import NextLink from 'next/link'
import { type HTMLAttributes, type ReactNode } from 'react'

import { type SanityLink } from '@data/sanity/queries/types/content'
import { getLinkPageUrl, type PageType } from '@lib/routes'

import Button, {
  type ButtonColor,
  type ButtonSize,
  type ButtonVariant,
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import ExternalLink from './exernal-link'
import HubSpotLink from './hubsport-link'

export interface LinkProps {
  link: SanityLink
  onClick?: () => void
  nextLinkClassName?: string
  className?: string
  children?: ReactNode
  buttonVariant?: ButtonVariant
  buttonSize?: ButtonSize
  buttonColor?: ButtonColor
}

const Link = ({
  link,
  children,
  tabIndex,
  onClick,
  nextLinkClassName,
  className,
  buttonVariant,
  buttonSize,
  buttonColor,
  'aria-label': ariaLabel,
}: LinkProps & HTMLAttributes<HTMLAnchorElement>) => {
  const { id, url, hubSpotFormUrl, isButton, fontCase, buttonStyles } = link
  const pageType = link.page && (link.page.type as PageType)
  const pageSlug = link.page?.slug

  // HubSpot form link
  if (hubSpotFormUrl) {
    return (
      <HubSpotLink
        id={id}
        hubSpotFormUrl={hubSpotFormUrl}
        ariaLabel={ariaLabel}
        tabIndex={tabIndex}
        buttonVariant={buttonVariant}
        buttonSize={buttonSize}
        buttonColor={buttonColor}
        buttonStyles={buttonStyles}
        isButton={isButton}
        fontCase={fontCase}
        className={className}
      >
        {children}
      </HubSpotLink>
    )
  }

  // External link
  if (url) {
    return (
      <ExternalLink
        id={id}
        url={url}
        ariaLabel={ariaLabel}
        tabIndex={tabIndex}
        buttonVariant={buttonVariant}
        buttonSize={buttonSize}
        buttonColor={buttonColor}
        buttonStyles={buttonStyles}
        isButton={isButton}
        fontCase={fontCase}
        className={className}
      >
        {children}
      </ExternalLink>
    )
  }

  // Internal Page
  if (!pageType) {
    return null
  }

  const pageUrl = getLinkPageUrl(pageType, pageSlug)

  const clickHandler = () => {
    if (onClick) {
      onClick()
    }
  }
  const beforeInputHandler = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <NextLink href={pageUrl} className={nextLinkClassName}>
      <Button
        id={id}
        role="link"
        tabIndex={tabIndex}
        onClick={clickHandler}
        onBeforeInput={beforeInputHandler}
        aria-label={ariaLabel}
        variant={buttonVariant ?? getButtonVariant(buttonStyles?.variant)}
        size={buttonSize ?? getButtonSize(buttonStyles?.size)}
        color={buttonColor ?? getButtonColor(buttonStyles?.color)}
        icon={buttonStyles?.icon}
        iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
        className={cx(
          {
            btn: isButton,
            'w-full': buttonStyles?.isFullWidth,
          },
          fontCase ?? '',
          className
        )}
      >
        {children}
      </Button>
    </NextLink>
  )
}

export default Link
