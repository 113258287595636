import cx from 'classnames'

import { type IconProps } from './types'

const ChevronDownIcon = ({ id, color, viewBox, className }: IconProps) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox ?? '0 0 28 28'}
      aria-labelledby={`chevrondown-${id}`}
      className={cx('w-[1em] h-[1em]', className)}
    >
      <title id={`chevrondown-${id}`}>ChevronDown</title>
      <path
        d="M5 10L14 19L23 10"
        fill="none"
        stroke={color}
        className="stroke-current"
        strokeWidth="2.5"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default ChevronDownIcon
