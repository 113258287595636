import cx from 'classnames'

import { type SanityFooterFragment } from '@data/sanity/queries/types/site'

import FooterBlock from './block'
import FooterCopyright from './copyright'

type FooterProps = SanityFooterFragment

const Footer = ({ blocks, copyright, paymentMethods }: FooterProps) => (
  <footer
    className="relative overflow-hidden bg-green-light text-green-dark pt-6 pb-10"
    role="contentinfo"
  >
    <div className="container">
      <div className="grid sm:grid-cols-2 lg:grid-cols-[50%,auto,auto,auto] mb-8">
        {blocks.map((block, index) => (
          <FooterBlock
            key={index}
            block={block}
            className={cx('sm:mr-20 py-6 md:py-10 last:mr-0', {
              'lg:pr-48 xl:pr-80': index === 0,
            })}
          />
        ))}
      </div>

      <div className="flex flex-wrap gap-6 justify-between">
        <FooterCopyright
          copyright={copyright}
          paymentMethods={paymentMethods ?? []}
        />

        <div className="inline-flex gap-x-2 text-sm">
          Built in Aarhus <span className="fi fi-dk text-lg"></span>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
