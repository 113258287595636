import cx from 'classnames'
import { type ButtonHTMLAttributes, forwardRef, useId } from 'react'

import {
  type SanityButtonColor,
  type SanityButtonIconAlignment,
  type SanityButtonSize,
  type SanityButtonVariant,
} from '@data/sanity/queries/types/link'

import CustomIcon, { type CustomIconType } from '@components/icons'

export type ButtonVariant = 'link' | 'primary' | 'secondary'

export type ButtonSize = 'default' | 'small' | 'normal' | 'large'

export type ButtonColor =
  | 'inherit'
  | 'default'
  | 'white'
  | 'gray-light'
  | 'gray'
  | 'green-dark'
  | 'yellow'

type ButtonIconAlignment = 'left' | 'right'

type ButtonIconSize = 'default' | 'small' | 'normal' | 'large'

export interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  color?: ButtonColor
  icon?: CustomIconType
  iconSize?: ButtonIconSize
  iconAlignment?: ButtonIconAlignment
  isActive?: boolean
}

export const getBaseClasses = (variant?: ButtonVariant, color?: ButtonColor) =>
  cx(
    'inline-flex items-center disabled:opacity-50 transition-all duration-300',
    {
      'justify-center rounded-full text-center border-[2px] font-medium !leading-[110%] hover:-translate-y-1 uppercase':
        variant !== 'link',
      'hover:opacity-60': color === 'inherit',
      'underline decoration-1 hover:decoration-2 transition-none':
        variant == 'link',
    }
  )

export const colorClasses: Record<
  ButtonVariant,
  Record<ButtonColor | 'default', string | string[]>
> = {
  primary: {
    yellow: [
      'border-yellow bg-yellow text-green-dark',
      'hover:border-yellow-saturated hover:bg-yellow-saturated',
    ],
    'green-dark': [
      'border-green-dark bg-green-dark text-yellow',
      'hover:border-green-darker hover:bg-green-darker',
    ],
    gray: ['border-gray bg-gray text-yellow'],
    'gray-light': ['border-gray-light bg-gray-light text-green-dark'],
    white: [
      'border-white bg-white text-green-dark',
      'hover:border-green-dark hover:bg-green-dark hover:text-white',
    ],
    default: [
      'bg-btn-primary-bg text-btn-primary-text border-btn-primary-border',
      'hover:bg-btn-primary-bg-hover hover:text-btn-primary-text-hover hover:border-btn-primary-border-hover',
    ],
    inherit: [],
  },
  secondary: {
    yellow: [
      'bg-transparent border-yellow text-white',
      'hover:border-yellow-saturated',
    ],
    'green-dark': [
      'bg-transparent border-green-dark text-green-dark',
      'hover:border-green-darker hover:text-green-darker',
    ],
    gray: [
      'bg-transparent border-gray text-gray',
      'hover:bg-gray hover:text-yellow',
    ],
    'gray-light': [
      'bg-transparent border-gray-light text-gray-light',
      'hover:bg-gray-light hover:text-green-dark',
    ],
    white: [
      'bg-transparent border-white text-white',
      'hover:bg-white hover:text-green-dark',
    ],
    default: [
      'bg-btn-secondary-bg border border-btn-secondary-border text-btn-secondary-text',
      'hover:bg-btn-secondary-bg-hover hover:text-btn-secondary-text-hover hover:border-btn-secondary-border-hover',
    ],
    inherit: ['bg-transparent border-current text-current'],
  },
  link: {
    yellow: ['text-yellow'],
    'green-dark': ['text-green-dark'],
    gray: ['text-gray'],
    'gray-light': ['text-gray-light'],
    white: ['text-white'],
    default: ['text-current'],
    inherit: ['text-current'],
  },
}

export const textColorClasses: Record<ButtonColor, string | string[]> = {
  yellow: 'text-yellow',
  'green-dark': 'text-green-dark',
  gray: 'text-gray',
  'gray-light': 'text-gray-light',
  white: 'text-white',
  default: 'text-current',
  inherit: 'text-current',
}

const sizeClasses: Record<ButtonSize, string | string[]> = {
  default: '',
  small: 'text-lg sm:text-xl',
  normal: 'text-2xl sm:text-3xl',
  large: 'text-4xl sm:text-5xl md:text-7xl',
}

const spacingClasses: Record<ButtonSize, string | string[]> = {
  default: '',
  small: 'px-2 py-1',
  normal: 'px-3 py-1',
  large: 'px-5 py-2.5',
}

export const iconSizeClasses: Record<ButtonIconSize, string | string[]> = {
  default: '',
  small: 'text-lg',
  normal: 'text-xl',
  large: 'text-2xl md:text-5xl',
}

const activeClasses: Record<
  ButtonVariant,
  Record<ButtonColor | 'default', string | string[]>
> = {
  primary: {
    yellow: 'border-green-dark bg-green-dark text-yellow',
    'green-dark': 'border-yellow bg-yellow text-green-dark',
    gray: 'border-gray bg-gray text-yellow',
    'gray-light': 'border-gray-light bg-gray-light text-green-dark',
    white: 'border-green-dark bg-green-dark text-white',
    default:
      'bg-btn-primary-bg-hover !text-btn-primary-text-hover border-btn-primary-border-hover',
    inherit: '',
  },
  secondary: {
    yellow: 'border-yellow bg-yellow !text-green-dark',
    'green-dark': 'border-green-dark bg-green-dark !text-yellow',
    gray: 'border-gray bg-gray !text-yellow',
    'gray-light': 'border-gray-light bg-gray-light !text-green-dark',
    white: 'border-white bg-white !text-green-dark',
    default:
      'bg-btn-secondary-bg-hover !text-btn-secondary-text-hover border-btn-secondary-border-hover',
    inherit: 'bg-transparent border-current text-current opacity-60',
  },
  link: colorClasses.link,
}

export const getButtonVariant = (
  variant?: SanityButtonVariant
): ButtonVariant | undefined => {
  if (!variant) {
    return
  }

  return variant as ButtonVariant
}

export const getButtonSize = (size?: SanityButtonSize): ButtonSize => {
  if (!size) {
    return 'default'
  }

  return size as ButtonSize
}

export const getButtonColor = (
  color?: SanityButtonColor
): ButtonColor | undefined => {
  if (!color) {
    return
  }

  return color as ButtonColor
}

export const getButtonIconAlignment = (
  iconAlignment?: SanityButtonIconAlignment
): ButtonIconAlignment | undefined => {
  if (!iconAlignment) {
    return
  }

  return iconAlignment as ButtonIconAlignment
}

export const getButtonStyles = ({
  variant = 'link',
  color = 'default',
  size = 'normal',
  isActive,
}: ButtonProps) => {
  return cx(
    getBaseClasses(variant, color),
    colorClasses[variant][color],
    isActive ? activeClasses[variant][color] : '',
    variant !== 'link' ? spacingClasses[size] : '',
    sizeClasses[size]
  )
}

export interface ButtonIconProps {
  name: CustomIconType
  alignment?: ButtonIconAlignment
  size?: ButtonIconSize | ButtonSize
  className?: string
}

export const ButtonIcon = ({
  name,
  alignment = 'right',
  size,
  className,
}: ButtonIconProps) => {
  const id = useId()

  return (
    <span
      className={cx(
        alignment === 'left'
          ? [
              'order-first',
              {
                'mr-2': size !== 'large',
                'mr-4': size === 'large',
              },
            ]
          : '',
        alignment === 'right'
          ? {
              'ml-3': size !== 'large',
              'ml-4': size === 'large',
            }
          : '',
        className
      )}
    >
      <CustomIcon id={`button-icon-${id}`} name={name} />
    </span>
  )
}

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      children,
      className,
      disabled,
      onClick,
      onBeforeInput,
      id,
      style,
      type,
      'aria-label': ariaLabel,
      variant,
      size,
      color,
      icon,
      iconSize,
      iconAlignment,
      isActive,
    }: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
    ref
  ) => {
    return (
      <button
        type={type}
        id={id}
        ref={ref}
        className={cx(
          'group',
          getButtonStyles({ variant, size, color, isActive }),
          className
        )}
        onClick={onClick}
        disabled={disabled}
        style={style}
        onBeforeInput={onBeforeInput}
        aria-label={ariaLabel}
      >
        {children}
        {icon && (
          <ButtonIcon
            name={icon}
            alignment={iconAlignment}
            size={iconSize ?? size}
            className={cx(
              {
                [`${textColorClasses.yellow} group-hover:text-yellow-saturated`]:
                  variant === 'secondary' && color === 'yellow',
              },
              iconSize
                ? iconSizeClasses[iconSize]
                : size
                ? iconSizeClasses[size]
                : {}
            )}
          />
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
