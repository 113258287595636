import cx from 'classnames'
import NextLink from 'next/link'

import { type SanityLinkFragment } from '@data/sanity/queries/types/link'

import { type PageType, getLinkPageUrl } from '@lib/routes'

interface DropdownItemProps {
  item: SanityLinkFragment
  className?: string
}

const DropdownItem = ({ item, className }: DropdownItemProps) => {
  const title = 'title' in item && item.title ? item.title : ''
  const description =
    'description' in item && item.description ? item.description : ''
  const externalUrl = 'url' in item && item.url ? item.url : ''
  const page = 'page' in item && item.page ? item.page : undefined
  const pageUrl =
    page && page.type ? getLinkPageUrl(page.type as PageType, page.slug) : ''

  return (
    <li
      className={cx(
        'py-3 border-solid border-b border-green-light last:border-b-0',
        className
      )}
    >
      <NextLink href={externalUrl || pageUrl} className="group/dropdown-item">
        <p className="text-xl group-hover/dropdown-item:underline">{title}</p>
        {description && (
          <p className="text-base text-green mt-1">{description}</p>
        )}
      </NextLink>
    </li>
  )
}

export default DropdownItem
