import urlBuilder from '@sanity/image-url'
import {
  type ImageFormat,
  type SanityImageSource,
} from '@sanity/image-url/lib/types/types'
import { type ImageLoader } from 'next/image'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { getSanityClient } from './sanity/client'
import { parseJson } from './helpers'

interface SourceOptionsProps {
  width?: number
  height?: number
  format?: ImageFormat
  quality?: number
  fitMax?: boolean
  autoFormat?: boolean
}

interface ImageDimensions {
  width?: number
  height?: number
}

const sanityUrlBuilder = urlBuilder(getSanityClient())

/**
 * Gets image loader for Next.js image component.
 */
export const getSanityImageLoader: ImageLoader = ({ src, width, quality }) => {
  const parsedSource = parseJson(src)
  const image = parsedSource?.asset
    ? (parsedSource as SanityImageFragment)
    : { asset: src.includes('://') ? { url: src } : { _ref: src } }

  const options: SourceOptionsProps = {
    width,
    quality,
    fitMax: true,
    autoFormat: true,
  }

  // Calculate image height
  const aspectRatio =
    'customRatio' in image && image.customRatio
      ? image.customRatio
      : 'dimensions' in image && image.dimensions.aspectRatio
  if (aspectRatio) {
    options.height = width / aspectRatio
  }

  return getSanityImageUrl(image, options) ?? ''
}

/**
 * Builds image source URL from image data and options.
 */
export function getSanityImageUrl(
  image?: SanityImageSource,
  {
    width,
    height,
    format,
    quality,
    fitMax,
    autoFormat,
  }: SourceOptionsProps = {}
) {
  if (!image) {
    return
  }

  try {
    let imageUrlBuilder = sanityUrlBuilder.image(image)

    if (width) {
      imageUrlBuilder = imageUrlBuilder.width(Math.round(width))
    }

    if (height) {
      imageUrlBuilder = imageUrlBuilder.height(Math.round(height))
    }

    if (format) {
      imageUrlBuilder = imageUrlBuilder.format(format)
    }

    if (quality) {
      imageUrlBuilder = imageUrlBuilder.quality(quality)
    }

    if (fitMax) {
      imageUrlBuilder = imageUrlBuilder.fit('max')
    }

    if (autoFormat) {
      imageUrlBuilder = imageUrlBuilder.auto('format')
    }

    return imageUrlBuilder.url()
  } catch (error) {
    console.error(error)
  }
}

/**
 * Gets scalar value from any value.
 */
const getScalarValue = (value?: string) => {
  const scalarValue = value?.match(/-?[\d.]+/g)?.join('')

  if (!scalarValue) {
    return
  }

  return Number(scalarValue)
}

/**
 * Extracts CSS unit from string value
 */
const getCSSUnit = (value?: string) => {
  return value
    ?.match(/(%|px|rem|em|vh|vw)/gi)
    ?.filter(Boolean)
    ?.join('')
}

/**
 * Get image dimensions based on its size and fixed height.
 */
export const getImageDimensions = (
  image: SanityImageFragment,
  forceWidth?: number,
  forceHeight?: number
): ImageDimensions => {
  // Return forced dimensions
  if (forceWidth && forceHeight) {
    return {
      width: forceWidth,
      height: forceHeight,
    }
  }

  if (!image.dimensions) {
    return {}
  }

  // Return dimensions for fixed image height
  const fixedHeight = getScalarValue(image.fixedHeight)

  if (fixedHeight) {
    const unit = getCSSUnit(image.fixedHeight)
    const unitScale = unit === 'rem' ? 16 : 1

    return {
      width:
        fixedHeight *
        (image.customRatio || image.dimensions.aspectRatio) *
        unitScale,
      height: fixedHeight * unitScale,
    }
  }

  // Return dimensions for custom aspect ratio
  if (
    image.customRatio &&
    image.customRatio.toFixed(2) !== image.dimensions.aspectRatio.toFixed(2)
  ) {
    if (image.customRatio > image.dimensions.aspectRatio) {
      return {
        width: image.dimensions.width,
        height: image.dimensions.width / image.customRatio,
      }
    }

    return {
      width: image.dimensions.height * image.customRatio,
      height: image.dimensions.height,
    }
  }

  // Return original dimensions
  return {
    width: image.dimensions.width,
    height: image.dimensions.height,
  }
}
