import cx from 'classnames'

import { type IconProps } from './types'

const CheckmarkIcon = ({ id, color, viewBox, className }: IconProps) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox ?? '0 0 14 11'}
      aria-labelledby={`checkmark-${id}`}
      className={cx('w-[1em] h-[1em]', className)}
    >
      <title id={`checkmark-${id}`}>Checkmark</title>
      <path
        d="M13.3524 0.764771L4.61707 9.23536L0.646484 5.38509"
        fill="none"
        stroke={color}
        className="stroke-current"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default CheckmarkIcon
