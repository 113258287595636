import cx from 'classnames'
import dynamic from 'next/dynamic'
import { useContext } from 'react'

import { type SanityLink } from '@data/sanity/queries/types/content'
import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { getImageDimensions } from '@lib/image'
import { StringsContext } from '@lib/strings'

import CustomLink from '@components/link'

const Photo = dynamic(() => import('@components/photo'))

interface LogoLinkProps {
  id: string
  image: SanityImageFragment
  className?: string
}

const LogoLink = ({ id, image, className }: LogoLinkProps) => {
  const strings = useContext(StringsContext)

  const imageDimensions = getImageDimensions(image)
  const link: SanityLink = {
    page: {
      id: `home-page-link-${id}`,
      type: 'homePage',
    },
  }

  return (
    <div
      className={cx(
        'flex flex-col justify-center items-center z-10 transition-opacity',
        className
      )}
    >
      <div className="flex flex-col justify-center items-center py-4 w-full">
        <CustomLink
          link={link}
          aria-label={strings.goHomeLabel}
          nextLinkClassName="flex"
        >
          <Photo
            image={image}
            width={imageDimensions.width}
            height={imageDimensions.height}
            priority
            className="inline-block relative overflow-hidden"
            imageClassName="block absolute top-0 right-0 bottom-0 left-0 m-auto min-w-[100%] max-w-[100%] min-h-[100%] max-h-[100%] w-0 h-0 overflow-hidden"
            style={{
              width: imageDimensions.width,
              height: imageDimensions.height,
            }}
          />
        </CustomLink>
      </div>
    </div>
  )
}

export default LogoLink
