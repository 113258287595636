import Head from 'next/head'

import { type SanityAnyPage } from '@data/sanity/queries/types/page'
import { type SanitySeo } from '@data/sanity/queries/types/site'
import { getSanityImageUrl } from '@lib/image'
import { colors } from '@lib/theme'

interface HeadSeoProps {
  siteSeo: SanitySeo
  page: SanityAnyPage
  canonicalUrl?: string
  schemas?: string[]
}

const HeadSeo = ({ siteSeo, page, canonicalUrl, schemas }: HeadSeoProps) => {
  const metaTitle = page.seo?.metaTitle ?? siteSeo.metaTitle
  const metaDescription = page.seo?.metaDesc ?? siteSeo.metaDesc
  const shareTitle = page.seo?.shareTitle ?? siteSeo.shareTitle
  const shareDescription = page.seo?.shareDesc ?? siteSeo.shareDesc
  const shareGraphicUrl = getSanityImageUrl(
    page.seo?.shareGraphic ?? siteSeo.shareGraphic,
    {
      width: 1200,
      height: 630,
    }
  )

  const siteTitle = siteSeo.siteTitle

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="format-detection" content="telephone=no" />
      {!!page.noIndex && <meta name="robots" content="noindex" />}

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/safari-pinned-tab.svg"
        color={colors.yellow.DEFAULT}
      />
      <meta name="msapplication-TileColor" content={colors.yellow.DEFAULT} />
      <meta name="theme-color" content={colors.yellow.DEFAULT} />

      <link
        rel="preconnect"
        href="https://cdn.sanity.io"
        crossOrigin="anonymous"
      />

      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="title" property="og:title" content={shareTitle} />
      <meta name="twitter:title" content={shareTitle} />
      <meta
        name="description"
        property="og:description"
        content={shareDescription}
      />
      <meta name="twitter:description" content={shareDescription} />

      {shareGraphicUrl && (
        <>
          <meta name="image" property="og:image" content={shareGraphicUrl} />
          <meta name="twitter:image" content={shareGraphicUrl} />
        </>
      )}

      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="og:site_name" content={siteTitle} />

      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {schemas && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context":"https://schema.org","@graph":[${schemas.join(
              ','
            )}]}`,
          }}
        />
      )}
    </Head>
  )
}

export default HeadSeo
