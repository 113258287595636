import cx from 'classnames'
import NextLink from 'next/link'

import { type SanityLinkFragment } from '@data/sanity/queries/types/link'

import { type PageType, getLinkPageUrl } from '@lib/routes'

interface MegaNavigationItemProps {
  item: SanityLinkFragment
  className?: string
  onClick?: () => void
}

const MegaNavigationItem = ({
  item,
  className,
  onClick,
}: MegaNavigationItemProps) => {
  const title = 'title' in item && item.title ? item.title : ''
  const description =
    'description' in item && item.description ? item.description : ''
  const externalUrl = 'url' in item && item.url ? item.url : ''
  const page = 'page' in item && item.page ? item.page : undefined
  const pageUrl =
    page && page.type ? getLinkPageUrl(page.type as PageType, page.slug) : ''

  return (
    <NextLink
      onClick={onClick}
      href={externalUrl || pageUrl}
      className={cx(
        'group/meganav-item flex flex-col justify-between h-full relative rounded-md',
        'hover:bg-green-subtle p-5 pl-0',
        'before:absolute before:rounded-md before:-left-5 before:top-0 before:w-full before:h-full hover:before:bg-green-subtle before:z-0',
        className
      )}
    >
      <div className="relative z-1">
        <p className="text-2xl group-hover/meganav-item:underline">{title}</p>
        {description && (
          <p className="text-base text-green mt-1">{description}</p>
        )}
      </div>
    </NextLink>
  )
}

export default MegaNavigationItem
