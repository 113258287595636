import cx from 'classnames'

import { type IconProps } from './types'

const CartIcon = ({ id, color, viewBox, className }: IconProps) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox ?? '0 0 28 28'}
      aria-labelledby={`cart-${id}`}
      className={cx('w-[1em] h-[1em]', className)}
    >
      <title id={`cart-${id}`}>Cart</title>

      <rect
        x="4.75"
        y="7.75"
        width="18.5"
        height="16.5"
        stroke={color}
        className="stroke-current"
        strokeWidth="1.5"
      />
      <path
        d="M19 7C19 4.23858 16.7614 2 14 2C11.2386 2 9 4.23858 9 7"
        stroke={color}
        className="stroke-current"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default CartIcon
