import { motion, type Variants } from 'framer-motion'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import {
  type CSSProperties,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import { useWindowSize } from 'usehooks-ts'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { type SanityAnyPage } from '@data/sanity/queries/types/page'
import { pageTransitionSpeed } from '@lib/animate'
import { SiteContext } from '@lib/site'
import { StringsContext } from '@lib/strings'

import Footer from '@modules/shared/footer'
import Header, { type HeaderSizeValues } from '@modules/shared/header'
import HeadSeo from './head-seo'

const CookieBar = dynamic(() => import('@modules/shared/cookie-bar'))
const PromoBar = dynamic(() => import('@modules/shared/promo-bar'))
const VideoModal = dynamic(() => import('@components/video/video-modal'))
const LocomotiveScrollProvider = dynamic(() =>
  import('react-locomotive-scroll').then(
    (module) => module.LocomotiveScrollProvider
  )
)

type CSSPropertiesWithHeader = CSSProperties & {
  '--headerHeight'?: string
}

interface LayoutProps {
  site: SanitySiteFragment
  page: SanityAnyPage
  children: ReactNode
  canonicalUrl?: string
  schemas?: string[]
  hasSmoothScroll?: boolean
  draftMode?: boolean
}

const variants: Variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: pageTransitionSpeed / 1000,
      delay: 0.2,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: pageTransitionSpeed / 1000,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
}

const DraftModeOverlay = () => (
  <div className="sticky bottom-0 inset-x-0 z-90 bg-white bg-opacity-95">
    <div className="flex justify-between items-center container py-1">
      <p className="m-0 text-sm">Preview mode is active.</p>
      <NextLink href="/api/disable-draft" prefetch={false}>
        <button className="underline decoration-1 hover:decoration-2 text-current text-sm">
          Exit preview mode
        </button>
      </NextLink>
    </div>
  </div>
)

const Layout = ({
  site,
  page,
  canonicalUrl,
  schemas,
  hasSmoothScroll,
  draftMode,
  children,
}: LayoutProps) => {
  const strings = useContext(StringsContext)
  const { videoModal, setHeaderHeight } = useContext(SiteContext)

  const scrollContainerRef = useRef(null)
  const topObserverRef = useRef(null)

  const { route } = useRouter()
  const windowSize = useWindowSize()

  // Save relevant header sizes
  const [headerSizeStyle, setHeaderSizeStyle] =
    useState<CSSPropertiesWithHeader>({})

  // Set window height lock (with Safari/iOS hack)
  const [lockHeight, setLockHeight] = useState(false)
  const hasChin =
    typeof window !== 'undefined' &&
    !!navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    !!navigator.userAgent.match(/AppleWebKit/)

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof windowSize !== 'undefined' &&
      !lockHeight
    ) {
      document.body.style.setProperty('--vh', `${windowSize.height * 0.01}px`)
      setLockHeight(hasChin)
    }
  }, [hasChin, lockHeight, windowSize])

  const hasTransparentHeader =
    'hasTransparentHeader' in page && !!page.hasTransparentHeader

  const handleHeaderResize = useCallback(
    ({ height }: HeaderSizeValues) => {
      setHeaderHeight(height)

      setHeaderSizeStyle({
        '--headerHeight': `${height}px`,
      })
    },
    [setHeaderHeight]
  )

  let pageContent = <>{children}</>

  if (!!site.settings?.pageAnimation) {
    pageContent = (
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={variants}
      >
        {pageContent}
      </motion.div>
    )
  }

  pageContent = (
    <div style={headerSizeStyle}>
      {site.header.promo.enabled && (
        <PromoBar
          display={site.header.promo.display}
          text={site.header.promo.text}
          link={site.header.promo.link}
        />
      )}

      <a
        href="#content"
        className="block fixed top-0 left-1/2 transform -translate-x-1/2 -translate-y-full z-90 px-2 py-1 bg-pageBG text-pageText text-xs font-semibold uppercase focus:translate-y-0 focus:outline-none"
      >
        {strings.skipToContent}
      </a>

      <Header
        menuDesktopLeft={site.header.menuDesktopLeft}
        menuDesktopRight={site.header.menuDesktopRight}
        menuMobilePrimary={site.header.menuMobilePrimary}
        menuMobileSecondary={site.header.menuMobileSecondary}
        logo={site.identity.logo}
        invertedLogo={site.identity.invertedLogo}
        isTransparent={hasTransparentHeader}
        onResize={handleHeaderResize}
        topObserverRef={topObserverRef}
      />

      <div
        className="min-h-screen flex flex-col justify-between overflow-x-hidden"
        data-scroll-container={hasSmoothScroll}
        ref={scrollContainerRef}
      >
        <main id="content" data-scroll-content={hasSmoothScroll}>
          <span
            ref={topObserverRef}
            className="relative top-[var(--headerHeight)]"
          />

          {pageContent}
        </main>

        <Footer
          blocks={site.footer.blocks}
          copyright={site.footer.copyright}
          paymentMethods={site.footer.paymentMethods}
        />
      </div>

      {videoModal.isOpen && <VideoModal />}
    </div>
  )

  if (hasSmoothScroll) {
    pageContent = (
      <LocomotiveScrollProvider
        options={{
          smooth: true,
        }}
        watch={[route]}
        containerRef={scrollContainerRef}
      >
        {pageContent}
      </LocomotiveScrollProvider>
    )
  }

  return (
    <>
      <HeadSeo
        siteSeo={site.seo}
        page={page}
        canonicalUrl={canonicalUrl}
        schemas={schemas}
      />

      {site.cookieConsent.enabled && (
        <CookieBar
          message={site.cookieConsent.message}
          link={site.cookieConsent.link}
        />
      )}

      {pageContent}

      {draftMode && <DraftModeOverlay />}
    </>
  )
}

export default Layout
