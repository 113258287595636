import { useContext } from 'react'

import { type SanityLink } from '@data/sanity/queries/types/content'
import { type HubSpotFormUrl } from '@data/sanity/queries/types/link'

import ExternalLink from './exernal-link'
import { FormValueContext } from './form'
import { LinkProps } from './link'

type HubSpotLinkProps = Pick<
  SanityLink,
  'id' | 'fontCase' | 'buttonStyles' | 'isButton'
> &
  Pick<
    LinkProps,
    'buttonVariant' | 'buttonSize' | 'buttonColor' | 'children'
  > & {
    hubSpotFormUrl: HubSpotFormUrl
    ariaLabel?: string
    tabIndex?: number
    className?: string
  }

const HubSpotLink = ({
  id,
  hubSpotFormUrl,
  ariaLabel,
  tabIndex,
  buttonVariant,
  buttonSize,
  buttonColor,
  buttonStyles,
  isButton,
  fontCase,
  className,
  children,
}: HubSpotLinkProps) => {
  const { formValues } = useContext(FormValueContext)

  // Add dynamic URL parameters using form values
  const searchParams = new URLSearchParams()
  hubSpotFormUrl.dynamicParameters?.forEach(
    ({ source, hubSpotInternalName }) => {
      if (formValues && source in formValues) {
        searchParams.set(hubSpotInternalName, formValues[source])
      }
    }
  )

  return (
    <ExternalLink
      id={id}
      url={`${hubSpotFormUrl.url}?${searchParams.toString()}`}
      ariaLabel={ariaLabel}
      tabIndex={tabIndex}
      buttonVariant={buttonVariant}
      buttonSize={buttonSize}
      buttonColor={buttonColor}
      buttonStyles={buttonStyles}
      isButton={isButton}
      fontCase={fontCase}
      className={className}
    >
      {children}
    </ExternalLink>
  )
}

export default HubSpotLink
