import { motion } from 'framer-motion'
import { useContext, useEffect, useState } from 'react'

import { type SanityHeaderFragment } from '@data/sanity/queries/types/site'
import { SiteContext } from '@lib/site'

import Menu from '@blocks/navigation/menu'

type MobileNavigationMenuProps = Pick<
  SanityHeaderFragment,
  'menuMobilePrimary' | 'menuMobileSecondary'
>

const MobileNavigationMenu = ({
  menuMobilePrimary,
  menuMobileSecondary,
}: MobileNavigationMenuProps) => {
  const { mobileMenu, toggleMobileMenu } = useContext(SiteContext)

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!isLoaded && mobileMenu.isOpen) {
      setIsLoaded(true)
    }
  }, [isLoaded, mobileMenu.isOpen])

  if (!isLoaded) {
    return null
  }

  return (
    <motion.div
      initial="hide"
      animate={mobileMenu.isOpen ? 'show' : 'hide'}
      variants={{
        show: {
          x: '0%',
        },
        hide: {
          x: '-100%',
        },
      }}
      transition={{
        duration: 0.8,
        ease: [0.16, 1, 0.3, 1],
      }}
      className="fixed top-0 left-0 w-full h-screen z-50 flex flex-col bg-pageBG text-pageText max-w-[500px] sm:pl-5"
    >
      <div className="flex-1 flex flex-col overflow-y-scroll px-4 pb-4 pt-[calc(var(--headerHeight,10rem)+1.5rem)] no-scrollbar">
        {!!menuMobilePrimary.items && (
          <div>
            <Menu
              id={menuMobilePrimary.slug}
              items={menuMobilePrimary.items}
              onClick={() => toggleMobileMenu(false)}
              isHeaderMobilePrimaryMenu
            />
          </div>
        )}

        {!!menuMobileSecondary.items && (
          <div className="pt-6">
            <Menu
              id={menuMobileSecondary.slug}
              items={menuMobileSecondary.items}
              onClick={() => toggleMobileMenu(false)}
              isHeaderMobileSecondaryMenu
            />
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default MobileNavigationMenu
