import cx from 'classnames'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'

import LogoLink from './logo-link'

interface LogoProps {
  isInvertedLogo: boolean
  logo?: SanityImageFragment
  invertedLogo?: SanityImageFragment
}

const Logo = ({ isInvertedLogo, logo, invertedLogo }: LogoProps) => {
  const isInvertedLogoVisible = !!invertedLogo?.asset && isInvertedLogo

  return (
    <div className="relative">
      {logo?.asset && (
        <LogoLink
          id="logo"
          image={logo}
          className={cx('transition-opacity', {
            'opacity-0': isInvertedLogoVisible,
          })}
        />
      )}

      {invertedLogo?.asset && (
        <LogoLink
          id="inverted-logo"
          image={invertedLogo}
          className={cx('transition-opacity absolute inset-0', {
            'opacity-0': !isInvertedLogoVisible,
          })}
        />
      )}
    </div>
  )
}

export default Logo
