import cx from 'classnames'

import { type IconProps } from './types'

const ChevronRightIcon = ({ id, color, viewBox, className }: IconProps) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox ?? '0 0 28 28'}
      aria-labelledby={`chevronright-${id}`}
      className={cx('w-[1em] h-[1em]', className)}
    >
      <title id={`chevronright-${id}`}>ChevronRight</title>
      <path
        d="M10 23L19 14L10 5"
        fill="none"
        stroke={color}
        className="stroke-current"
        strokeWidth="2.5"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default ChevronRightIcon
