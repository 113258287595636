import cx from 'classnames'
import { useContext } from 'react'

import { SiteContext } from '@lib/site'

interface MobileNavigationBackdropProps {
  isOpen: boolean
}

const MobileNavigationBackdrop = ({
  isOpen,
}: MobileNavigationBackdropProps) => {
  const { toggleMobileMenu } = useContext(SiteContext)

  return (
    <div
      role="presentation"
      onClick={() => toggleMobileMenu(false)}
      className={cx(
        'fixed inset-0 z-40 bg-backdrop bg-opacity-40 transition-opacity duration-150 ease-linear',
        {
          'pointer-events-none opacity-0': !isOpen,
          'pointer-events-auto opacity-100 backdrop-filter-blur-[6px]': isOpen,
        }
      )}
    />
  )
}

export default MobileNavigationBackdrop
